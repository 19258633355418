body {
  background-color: #c41d5e !important;
}

h1 {
  color: #eec5d5 !important;
  /* text-shadow: 0 0 3vw #F40A35 !important; */
  animation: glow 1.5s ease infinite;
        -moz-animation: glow 1.5s ease infinite;
        -webkit-animation: glow 1.5s ease infinite;
}

@keyframes glow {
        0%,
        100% {
          text-shadow: 0 0 1vw #c41d5e, 0 0 0.3vw #c41d5e, 0 0 .1vw #c41d5e, 0 0 .1vw #c41d5e, 0 0 .4vw #FED128;
        }
        50% {
          text-shadow: 0 0 .5vw #800E0B, 0 0 1.5vw #800E0B, 0 0 5vw #800E0B, 0 0 5vw #800E0B, 0 0 .2vw #800E0B;
          color: #806914;
        }
      }

.radix-themes {
  --heading-font-family: 'Ms Madi', cursive !important;
--accent-a11: '#de909e'
}
